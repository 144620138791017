@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Source+Serif+Pro:ital,wght@0,200;1,200&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
body {
background-color: #ffffff;
    overflow-x: hidden;
}

html{
    font-family: 'Poppins', sans-serif;
    color: #000000;
}


header{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    justify-content:space-between;
    max-width: 1600px;
    margin: auto;
}




.navigation > li{
    margin-left: 20px;
}

.navlink > a{
   font-size: 20px; 
    color:#000000;
    margin-left: 20px;
}

h1{
    color: #fff;
    font-weight: 200;
    font-size: 25px;
}

.landingimage{
    background-image: url(./images/landingtotry.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    height: 100vh;
}




.primary-nav{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 800;
    font-size: 20px;
}

.active-nav {
    position: fixed;
    display: inline-block;
    inset: 0 0 0 38%;
    flex-direction: column;
    padding: min(30vh,10rem) 2em;
    text-align: center;
    background: hsl(0deg 0% 100% / 50%);
    transition: transform 350ms ease-in;
    backdrop-filter: blur(10px);
    z-index: 2;
}



.mobile-nav-toggle{
    display: none;
}


@media (max-width: 1000px) {
   
    .primary-nav{
        position: fixed;
        display: inline-block;
        inset: 0 0 0 38%;
        flex-direction: column;
        padding: min(30vh,10rem) 2em;
        text-align: center;
        background: hsl(0deg 0% 100% / 50%);
        transform: translateX(100%);
        transition: transform 350ms ease-in;
        backdrop-filter: blur(10px);
        z-index: 2;
   
    }
    .navlink{
        margin-bottom: 40px;
    }
    .mobile-nav-toggle{
        display: block;
        position: absolute;
        right: 2rem;
        z-index: 998;
    }
    .navlink > a{
        margin-left: 0;
    }
}
.hamburger .line{
    width: 30px;
    height: 3px;
    background-color: #000000;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
  
  
  .hamburger:hover{
    cursor: pointer;
  }
  
  /* ONE */
  
  #hamburger-1.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #hamburger-1.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
  }
  
  #hamburger-1.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-3px) rotate(-45deg);
  }


h2{
    color: #000000;
    font-size: 40px;
    font-weight: 200;
    text-align: center;
    font-family: 'Source Serif Pro', serif;
}

#demo-text{
  font-size: 6vmax;
  text-align: center;
  font-weight: 700;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: url(./images/landingtotry.JPG);
  background-position: bottom;
  letter-spacing: 5px;
}

#demo-textsmall {
	font-size: 4vmax;
    text-align: center;
	font-weight: 700;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	background-image: url('images/homepage.JPG');
    background-position: revert;
    margin-bottom: 40px;
  }


.aboutcontainer{
    
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-inline: 20px;
    margin: auto;
    max-width: 1200px;
    
}

.headshot{
    width:100%;
    max-width: 400px;
    margin-block: 20px;
}

.aboutcontainer > p{
    color: #000000;
    max-width: 400px;
    margin-block: 20px;
    text-align: left;
    line-height: 30px;
}

.landingbutton {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: solid 1.3px #a5a5a5;
  color: #a5a5a5;
  width: 165px;
  height: 40px;
  margin-top: 400px;
  font-size: 15px;
  font-weight: 500;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.landingbutton > a{
    color: #cdc9c9;
}


@media only screen and (max-width: 700px) {
    .aboutcontainer {
   flex-direction: column;
    }
    .aboutsection2 {
        flex-direction:column-reverse;
         }
    .contactcontainer{
        flex-direction: column;
         }
  }

  .quote {
    display: inline-block;
    margin: 1em;
    overflow: hidden;
  }
  .quote .box {
    background-color: #ffffff;
    border: solid 2px #757575;
    display: inline-block;
    margin: 0;
    padding: 1em;
    position: relative;
    max-width: 400px;
    margin-bottom: 20px;
  }
  .quote .box:before {
    background-color: #ffffff;
    bottom: -10%;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: -10%;
    transform: rotate(295deg) skew(5deg);
  }
  .quote .box cite {
    display: block;
    font-style: italic;
    text-align: right;
    color: #9B2B0A;
  }
  .quote .box cite:before {
    content: "- ";
  }
  .quote .box > * {
    position: relative;
    z-index: 1;
  }


  .aboutsection2{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-inline: 20px;
    width: 100%;
    margin: auto;
    max-width: 1200px;
  }

  p > span{
   font-size: 20px;
  }

  #gallery{
    -webkit-column-count:4;
    -moz-column-count:4;
    column-count:4;
    
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
    padding-inline: 20px;
    margin-block: 40px;
  }
  @media (max-width:1200px){
    #gallery{
    -webkit-column-count:3;
    -moz-column-count:3;
    column-count:3;
      
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  }
  @media (max-width:800px){
    #gallery{
    -webkit-column-count:2;
    -moz-column-count:2;
    column-count:2;
      
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  }
  @media (max-width:600px){
    #gallery{
    -webkit-column-count:1;
    -moz-column-count:1;
    column-count:1;
  }  
  }
  #gallery img,#gallery video {
    width:100%;
    height:auto;
    margin: 4% auto;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
  }





  footer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-block: 10px;
    color: #373737;
    padding-inline: 20px;
    text-align: center;
  }

  footer > p{
    margin-top: 10px;
  }

  p > a{
    color: #9B2B0A;
    text-decoration: underline;
    font-weight: bold;
  }



footer > a{
  color: #9B2B0A;
}




.logo-nav{
  height:100px;
}

.contactp{
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.contactpabove{
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

nav a.active{
  color: #9B2B0A;
  font-weight: bold;
}

form{
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
  max-width: 400px;
  padding: 20px;
}

form > textarea{

  resize: none;
  margin-bottom: 20px;
  height: 40px;

}

form > .submitbutton{
  width: 30%;
  margin: auto;
  /* color: darkgrey; */
  background: #ebebeb;
  color: #000000;
-webkit-appearance: none;
border-radius: 0;
}

.hiddenread{
  display: none;
}

.readmore{
  display: block;
  font-size: 16px;
}

.readmorestyle{
  color: #9B2B0A;
  font-size: 16px;

}

.modal{
  display: none;
}

.openmodal{
  display: block;
  background: #000000ab;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 20px;
}

.modalcontainer{
  background-color: rgb(255, 255, 255);
    max-width: 700px;
    margin: auto;
    text-align: center;
}

.modalcontainer > img{
  display: flex;
  width: 100%;
}

form > input{
    border: 1px solid gray;
    height: 40px;
}

form > textarea{
  border: 1px solid gray;
    height: 80px;
}


